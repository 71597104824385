<template>
  <div class="ticket-page">
    <div class="tabs-box">
      <div class="content">
        <div
          v-for="item in tabList"
          :class="activeTab == item.id ? 'tab-item active' : 'tab-item'"
          :key="item.id"
          @click="changeTab(item.id)"
        >
          <span class="tab-name">{{ item.name }}</span>
          <span class="line"></span>
        </div>
      </div>
    </div>
    <div class="to-coupon" @click="toCouponCenter">
      <div class="to-coupon-title">
        <svg-icon iconClass="coupon" class="coupon-svg"></svg-icon>
        <span>领券下单更优惠</span>
      </div>
      <div class="dp-a">
        <span style="color: #999999">领券中心</span>
        <van-icon class="arrow" name="arrow" />
      </div>
    </div>
    <van-pull-refresh
      :class="['pull-coupon-list', isSafari && 'safariHeight']"
      v-model="refreshing"
      @refresh="onRefresh"
    >
      <van-list
        v-if="ticketList && ticketList.length > 0"
        v-model="loading"
        :finished="finished"
        :loading-text="loadingText"
        finished-text="没有更多了"
        @load="getDataList"
      >
        <div
          v-for="(item, index) in ticketList"
          :key="index"
          class="ticket-item"
        >
          <template v-if="activeTab == 10">
            <div class="ticket-item-box ticket-item-boxNew">
              <div class="ticket-item-left ticket-item-leftNew">
                <div class="ticket-infos">
                  <div class="ticket-item-icon">
                    <img :src="item.promotionImg" />
                  </div>
                  <div class="ticket-item-context">
                    <div class="ticket-context-title">
                      {{ item.couponName }}
                    </div>
                    <div class="ticket-context-ftitle one-line-hidden">
                      可用商品：{{ item.productName }}
                    </div>
                    <div class="ticket-context-ftitle">
                      {{ renderAttrName(item.specName, item.attrName) }}
                    </div>
                    <!-- <div class="ticket-context-detail ticket-context-detailNew">
                    <div class="ticket-context-detailL">详情信息</div>
                    <div
                      class="ticket-context-detailR"
                      @click="detailZk(index)"
                    >
                      <img
                        v-if="item.more"
                        src="../assets/images/sq.png"
                        alt=""
                      />
                      <img v-else src="../assets/images/more.png" alt="" />
                    </div>
                    </div>-->
                  </div>
                </div>
                <div class="ticket-context-time">
                  <div>
                    有效期至：{{
                      `${moment(item.endDate).format("YYYY.MM.DD HH:mm")}`
                    }}
                  </div>
                  <div class="ticket-context-detailR" @click="detailZk(index)">
                    <img v-if="item.more" src="../assets/images/sq.png" alt />
                    <img v-else src="../assets/images/more.png" alt />
                  </div>
                </div>
              </div>
              <div class="ticket-item-right">
                <span class="circle circle-top"></span>
                <span class="circle circle-bottom"></span>
                <div class="ticket-item-rightT">
                  {{ `商品${couponTypeList[item.couponType]}` }}
                </div>
                <div class="ticket-item-rightC">
                  <span class="unit-1">￥</span>
                  <span class="money">{{ item.amount }}</span>
                  <!-- <span class="unit-2">元</span> -->
                </div>
                <div class="ticket-item-rightB" @click="clickProduct(item)">
                  去使用
                </div>
              </div>
            </div>
            <div class="ticket-zk" v-if="item.more">
              <!-- <p>
                适用范围：仅限<span class="ticket-zk-span">xxxxxxx</span
                >店铺内商品可
              </p>-->
              <div style="display: flex">
                使用说明：{{ item.instructions }}
                <!-- <p v-html="item.instructions"></p> -->
              </div>
            </div>
          </template>
          <template v-if="activeTab == 20">
            <div class="ticket-item-box">
              <div class="ticket-item-left">
                <div class="ticket-item-context ticket-item-contextYsy">
                  <div class="ticket-context-title">{{ item.couponName }}</div>
                  <div class="ticket-context-ftitle one-line-hidden">
                    可用商品：{{ item.productName }}
                  </div>
                  <div class="ticket-context-ftitle">
                    {{ renderAttrName(item.specName, item.attrName) }}
                  </div>
                  <div class="ticket-context-time">
                    有效期至：{{
                      `${moment(item.endDate).format("YYYY.MM.DD HH:mm")}`
                    }}
                  </div>
                  <div class="ticket-context-detail">
                    <div class="ticket-context-detailL">详情信息</div>
                    <div
                      class="ticket-context-detailR"
                      @click="detailZk(index)"
                    >
                      <img v-if="item.more" src="../assets/images/sq.png" alt />
                      <img v-else src="../assets/images/more.png" alt />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="ticket-item-right ticket-item-rightYsy"
                style="background: #d8d8d8"
              >
                <span class="circle circle-top"></span>
                <span class="circle circle-bottom"></span>
                <div class="ticket-item-rightT">
                  {{ couponTypeList[item.couponType] }}
                </div>
                <div class="ticket-item-rightC">
                  <span class="unit-1">￥</span>
                  <span class="money">{{ item.amount }}</span>
                  <!-- <span class="unit-2">元</span> -->
                </div>
                <div class="ticket-item-rightB">已使用</div>
              </div>
            </div>
            <div class="ticket-zk" v-if="item.more">
              <!-- <p>
                适用范围：仅限<span class="ticket-zk-span">xxxxxxx</span
                >店铺内商品可
              </p>-->
              <div style="display: flex">
                使用说明：
                <p v-html="item.instructions"></p>
              </div>
            </div>
          </template>
          <template v-if="activeTab == 30">
            <div class="ticket-item-box">
              <div class="ticket-item-left">
                <div class="ticket-item-context ticket-item-contextYgq">
                  <div class="ticket-context-title">{{ item.couponName }}</div>
                  <div class="ticket-context-ftitle one-line-hidden">
                    可用商品：{{ item.productName }}
                  </div>
                  <div class="ticket-context-ftitle">
                    {{ renderAttrName(item.specName, item.attrName) }}
                  </div>
                  <div class="ticket-context-time">
                    有效期至：{{
                      `${moment(item.endDate).format("YYYY.MM.DD HH:mm")}`
                    }}
                  </div>
                  <div class="ticket-context-detail">
                    <div class="ticket-context-detailL">详情信息</div>
                    <div
                      class="ticket-context-detailR"
                      @click="detailZk(index)"
                    >
                      <img v-if="item.more" src="../assets/images/sq.png" alt />
                      <img v-else src="../assets/images/more.png" alt />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="ticket-item-right ticket-item-rightYgq"
                style="background: #d8d8d8"
              >
                <span class="circle circle-top"></span>
                <span class="circle circle-bottom"></span>
                <div class="ticket-item-rightT">
                  {{ couponTypeList[item.couponType] }}
                </div>
                <div class="ticket-item-rightC">
                  <span class="unit-1">￥</span>
                  <span class="money">{{ item.amount }}</span>
                  <!-- <span class="unit-2">元</span> -->
                </div>
                <div class="ticket-item-rightB">已过期</div>
              </div>
            </div>
            <div class="ticket-zk" v-if="item.more">
              <!-- <p>
                适用范围：仅限<span class="ticket-zk-span">xxxxxxx</span
                >店铺内商品可
              </p>-->
              <div style="display: flex">
                使用说明：
                <p v-html="item.instructions"></p>
              </div>
            </div>
          </template>
        </div>
      </van-list>
      <div v-else class="no-list">
        <div class="no-img">
          <img src="../assets/images/no-card.png" alt />
        </div>
        <div class="no-tip">
          {{ isLogin ? "当前暂无优惠券" : "请登录后查看" }}
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import Vue from "vue";
import { List, PullRefresh } from "vant";
import { GetMyCoupons, getProductInfo } from "@/api/common";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import { Icon } from "vant";
import "../assets/images/svg/coupon.svg";
const couponTypeList = {
  10: "满减券",
  20: "折扣券",
  30: "随机金额券",
  40: "商品兑换券",
  50: "储值券",
  60: "抵扣券",
  70: "积分券",
};

export default {
  name: "Ticket",
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
  },
  data() {
    // 判断是否在Safari浏览器中
    var ua = navigator.userAgent.toLowerCase();
    let isSafari = false;
    if (
      ua.indexOf("applewebkit") > -1 &&
      ua.indexOf("mobile") > -1 &&
      ua.indexOf("safari") > -1 &&
      ua.indexOf("linux") === -1 &&
      ua.indexOf("android") === -1 &&
      ua.indexOf("chrome") === -1 &&
      ua.indexOf("ios") === -1 &&
      ua.indexOf("browser") === -1
    ) {
      isSafari = true;
    } else {
      isSafari = false;
    }
    return {
      moment,
      isSafari,
      couponTypeList,
      refreshing: false,
      loading: false,
      loadingText: "加载中..",
      finished: false,
      activeTab: 10,
      tabList: [
        {
          id: 10,
          name: "未使用",
        },
        {
          id: 20,
          name: "已使用",
        },
        {
          id: 30,
          name: "已过期",
        },
      ],
      ticketList: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {
    ...mapState(["isLogin"]),
  },
  created() {
    document.title = "我的卡券";
    this.getList();
    this.dispatchGetCardCount();
  },
  mounted() {
    console.log(this);
  },
  methods: {
    ...mapActions(["dispatchGetCardCount"]),

    // 去卡券中心
    toCouponCenter() {
      console.log(1);
      this.$router.push("/mall/CouponCenter");
    },
    // 获取订单列表
    getList() {
      this.pageIndex = 1;
      this.$nextTick(async () => {
        try {
          const res = await GetMyCoupons({
            pageSize: this.pageSize,
            pageIndex: this.pageIndex,
            state: this.activeTab,
          });
          if (res && res.code == 0) {
            this.ticketList = res.data.list || [];
            if (res.data.total === this.ticketList.length) {
              this.finished = true;
            } else {
              this.finished = false;
            }
            this.pageIndex = 2;
            this.refreshing = false;
            this.loading = false;
          } else {
            this.$toast(res.message);
            this.loading = false;
            this.finished = true;
          }
        } catch (error) {
          this.loading = false;
          this.finished = true;
        }
      });
    },
    async getDataList(type) {
      const res = await GetMyCoupons({
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        state: this.activeTab,
      });
      try {
        if (res && res.code == 0) {
          this.ticketList = [...this.ticketList, ...(res.data?.list || [])];
          this.$nextTick(() => {
            if (this.ticketList.length < res.data.total) {
              this.loading = false;
              this.pageIndex++;
            } else {
              this.finished = true;
              this.loading = true;
            }
            this.ticketList.forEach((item) => {
              item.more = false;
            });
          });
        } else {
          this.$toast(res.message);
          this.loading = false;
          this.finished = true;
        }
      } catch (error) {
        this.loading = false;
        this.finished = true;
      }
    },

    // 获取订单列表
    async getDataDownList() {
      const res = await GetMyCoupons({
        pageSize: 10,
        pageIndex: 1,
        state: this.activeTab,
      });
      if (res && res.code == 0) {
        this.ticketList = res.data?.list || [];
        this.refreshing = false;
        this.ticketList.forEach((item) => {
          item.more = false;
        });
      } else {
        this.$toast(res.message);
      }
    },

    // 下拉刷新
    onRefresh() {
      this.getList();
    },

    // 切换tab
    changeTab(activeTab) {
      if (this.activeTab == activeTab) return;
      this.activeTab = activeTab;
      this.ticketList = [];
      this.$nextTick(() => {
        this.getList();
      });
    },

    detailZk(index) {
      Vue.set(this.ticketList, index, {
        promotionImg: this.ticketList[index].promotionImg,
        couponName: this.ticketList[index].couponName,
        productName: this.ticketList[index].productName,
        startDate: this.ticketList[index].startDate,
        endDate: this.ticketList[index].endDate,
        couponType: this.ticketList[index].couponType,
        instructions: this.ticketList[index].instructions,
        amount: this.ticketList[index].amount,
        specName: this.ticketList[index].specName,
        attrName: this.ticketList[index].attrName,
        more: !this.ticketList[index].more,
      });
    },

    renderAttrName(productSpecName, productAttrName) {
      if (!productSpecName && !productAttrName) return "";
      if (!productAttrName) return productSpecName;
      return `${productSpecName};${productAttrName}`;
    },

    // 使用优化券
    clickProduct(data) {
      const { productType, upperProductId = "", isCanUse } = data;
      if (!isCanUse) {
        this.$toast("该商品不存在或已下架！");
        return;
      }
      // productType ==30 普通话费  productType == 40 慢充话费
      if (productType == 30 || productType == 40) {
        this.$router.push(
          `/mall/phoneBill?productType=${productType}&productId=${upperProductId}`
        );
        productType == 40 && this.$toast("已选中优惠商品");
      } else {
        this.$router.push(`/mall/ProductDetails?productId=${upperProductId}`);
        this.$toast("已选中优惠商品");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ticket-page {
  flex: 1;
  height: 100%;
  background-color: #f6f7f7;
  .to-coupon {
    display: flex;
    font-size: 0.24rem;
    justify-content: space-between;
    align-items: center;
    height: 0.88rem;
    color: #333333;
    background: #ffffff;
    border-radius: 0.16rem;
    margin: 0 0.2rem;
    margin-top: 0.3rem;
    padding: 0 0.32rem;

    .to-coupon-title {
      display: flex;
      align-items: center;
      font-size: 0.25rem;
    }

    .dp-a {
      .arrow {
        color: #999;
        margin-left: 0.04rem;
      }
    }

    .coupon-svg {
      width: 0.42rem;
      height: 0.32rem;
      margin-right: 0.18rem;
      padding-bottom: 0.04rem;
    }
  }
  .pull-coupon-list {
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 150px);

    &.safariHeight {
      height: calc(100vh - 5.4rem);
    }
  }
  .tabs-box {
    width: 100%;
    height: 0.98rem;
    overflow-x: auto;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding-top: 0.1rem;
    .content {
      display: flex;
      width: 7.02rem;
      margin: 0 auto;

      .tab-item {
        width: 2.34rem;
        padding: 0 0.2rem;
        font-size: 0.3rem;
        font-family: Helvetica;
        font-weight: 400;
        color: #9b9b9b;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.active {
          color: #000;
          font-family: Helvetica;
        }

        .line {
          width: 0.26rem;
          height: 0.08rem;
          background: #fff;
          border-radius: 0.05rem;
          margin-top: 0.08rem;
        }
      }
      .tab-item.active .line {
        background: #ff684a;
      }
      .tab-item2 {
        font-size: 0.24rem;

        &.active {
          color: #000;
          font-family: Helvetica;
          font-weight: 400;
          font-size: 0.25rem;
        }
      }
    }
  }

  .ticket-item {
    padding: 0 0.2rem;
    margin-top: 0.3rem;

    .ticket-item-box {
      display: flex;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.06);
      .ticket-item-left {
        display: flex;
        background-color: #fff;
        border-radius: 0.16rem;
        .ticket-infos {
          flex: 1;
        }
        &:first-child {
          flex: 1;
        }
        .ticket-item-icon {
          width: 2.2rem;
          margin: 0.32rem 0.16rem;
          > img {
            width: 100%;
            height: 100%;
            border-radius: 0.05rem;
          }
        }

        .ticket-item-context {
          margin-top: 0.22rem;
          width: 3.2rem;
          .ticket-context-title {
            font-size: 0.26rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 700;
            color: #000000;
            // margin-bottom: 0.1rem;
            width: 3.2rem;
            // white-space: pre-wrap;
            // display: -webkit-box;
            /* 弹性盒旧式语法 */
            // -webkit-box-orient: vertical;
            // text-overflow: ellipsis;
            // -webkit-line-clamp: 1;
            /* 限制两行 */
            // overflow: hidden;
            // text-indent: -3px;
            margin-top: 0.06rem;
          }

          .ticket-context-ftitle,
          .ticket-context-time,
          .ticket-context-detail {
            font-size: 0.2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            // margin-bottom: 0.1rem;
            width: 3.2rem;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            // margin-top: 0.1rem;
          }
          .one-line-hidden {
            margin: 0.04rem 0;
          }
          .ticket-context-time {
            padding-bottom: 0.2rem;
          }

          .ticket-context-detail {
            display: flex;
            justify-content: space-between;
            .ticket-context-detailL {
              font-size: 0.2rem;
              margin-top: 0.1rem;
            }
            .ticket-context-detailR {
              width: 0.24rem;
              height: 0.24rem;
              margin-right: 0.1rem;
              margin-top: 0.14rem;

              > img {
                width: 100%;
                height: 100%;
              }
            }
          }
          .ticket-context-detailNew {
            padding-bottom: 0.2rem;
          }
        }

        .ticket-item-contextYsy,
        .ticket-item-contextYgq {
          width: 4.6rem;
          margin-left: 0.4rem;
          padding: 0.1rem 0 0.2rem 0;
          .ticket-context-title,
          .ticket-context-ftitle,
          .ticket-context-time,
          .ticket-context-detail {
            width: 4.6rem;
          }
        }
      }

      .ticket-item-leftNew {
        display: flex;
        flex-direction: column;
        width: 5rem;
        div:first-child {
          display: flex;
        }
        .ticket-context-time {
          font-size: 0.2rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          margin-top: 0.2rem;
          display: flex;
          justify-content: space-between;
          margin-left: 0.16rem;
          margin-right: 0.16rem;
          padding-bottom: 0.1rem;
          .ticket-context-detailR {
            width: 0.24rem;
            height: 0.24rem;
            margin-right: 0.1rem;
            > img {
              margin-top: 0.06rem;
              margin-left: 0.1rem;
              width: 100%;
              height: 100%;
            }
          }
        }
        .ticket-item-icon {
          width: 1.28rem;
          height: 0.78rem;

          > img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .ticket-item-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(90deg, #fda07e, #ff705c 100%);
        flex: 1;
        border-radius: 0.16rem;
        position: relative;
        padding: 0.16rem 0 0.24rem;

        .circle {
          display: block;
          width: 0.16rem;
          height: 0.16rem;
          border-radius: 50%;
          background-color: #f6f7f7;
          position: absolute;
          left: -0.08rem;

          &.circle-top {
            top: -0.04rem;
          }
          &.circle-bottom {
            bottom: -0.06rem;
          }
        }

        .ticket-item-rightT {
          font-size: 0.2rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #fefffe;
        }
        .ticket-item-rightC {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0.16rem 0 0.16rem;
          .unit-1 {
            // width: 0.2rem;
            // height: 0.04rem;
            // background-color: #fff;
            // margin-right: 0.04rem;
            font-size: 0.24rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-top: 0.2rem;
          }
          .money {
            font-size: 0.56rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
          .unit-2 {
            font-size: 0.24rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-top: 0.16rem;
            margin-left: 0.02rem;
          }
        }
        .ticket-item-rightB {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1.3rem;
          height: 0.42rem;
          background: #fefffe;
          border-radius: 0.22rem;
          font-size: 0.22rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #fc632e;
        }
      }

      .ticket-item-rightYsy,
      .ticket-item-rightYgq {
        .ticket-item-rightB {
          color: #909399;
        }
      }
    }

    .ticket-item-boxNew {
      .ticket-item-right {
        padding: 0.2rem 0;
      }
    }

    .ticket-zk {
      font-size: 0.2rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 0px 0px 16px 16px;
      padding: 0.2rem 0 0.18rem 0.18rem;
      .ticket-zk-span {
        color: #fb6c38;
      }
    }
  }

  .no-list {
    width: 100%;
    height: 100%;
    // background: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0);

    .no-img {
      width: 2.4rem;
      height: 1.96rem;
      border-radius: 50%;
      overflow: hidden;
      // background-color: #ccc;
      margin-top: 30%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .no-tip {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      text-align: center;
      margin-top: 0.32rem;
    }
  }
}
</style>
